import { useEffect, useRef } from "react";
import howToImg1 from "../assets/how-to-1.png";
import howToImg2 from "../assets/how-to-2.png";
import howToImg3 from "../assets/how-to-3.png";

export default function HowTo() {
  const orderList = useRef();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // offsetTop
      if (window.scrollY >= document.querySelector(".howto").offsetTop - 400) {
        orderList.current.className = "howto-order howto-order-on";
      } else {
        orderList.current.className = "howto-order";
      }
    });
  }, []);

  return (
    <div className="howto" id="howTb">
      <section className="howto-inner">
        <h2 className="howto-title">
          <span>간편한</span> 환급 절차
        </h2>
        <ul className="howto-order" ref={orderList}>
          <li>
            <p className="howto-order-number">1</p>
            <p className="howto-order-text">
              간편인증을 통해
              <br />
              사업자 인증을 진행합니다
            </p>
            <img src={howToImg1} className="howto-order-img" alt="" />
          </li>
          <li>
            <p className="howto-order-number">2</p>
            <p className="howto-order-text">
              환급금 유무 조회 후,
              <br />
              계약서를 작성합니다
            </p>
            <img src={howToImg2} className="howto-order-img" alt="" />
          </li>
          <li>
            <p className="howto-order-number">3</p>
            <p className="howto-order-text">
              꼼꼼하게 세무사 검토 후,
              <br />
              환급 신청이 진행됩니다
            </p>
            <img src={howToImg3} className="howto-order-img" alt="" />
          </li>
        </ul>
      </section>
    </div>
  );
}
