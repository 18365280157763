import { Link } from "react-router-dom";
import introLogo from "../assets/text-logo.png";
import introPhoneFront from "../assets/intro-phone-front.png";
import introPhoneBack from "../assets/intro-phone-back.png";

export default function Intro() {
  return (
    <div className="intro">
      <div className="intro-inner">
        <section className="intro-text">
          <p className="intro-text-from">세무법인 프라이어의</p>
          <h2 className="intro-text-title">간편한 IT 세금 환급 서비스</h2>
          <img src={introLogo} className="intro-text-logo" alt="TaxBack" />
          <Link
            to="https://transfer.tax-back.kr/"
            className="intro-text-btn"
            target="_blank"
          >
            무료 환급 진단하기
          </Link>
        </section>
        <div className="intro-img">
          <div className="intro-img-all">
            <img src={introPhoneBack} className="intro-img-phone-back" alt="" />
            <img
              src={introPhoneFront}
              className="intro-img-phone-front"
              alt=""
            />
            <div className="intro-img-back1"></div>
            <div className="intro-img-back2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
