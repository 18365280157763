import { Reset } from "styled-reset";
import { BrowserRouter } from "react-router-dom";
import Skip from "./components/Skip";
import NavBar from "./components/NavBar";
import Intro from "./components/Intro";
import WhyTaxBack from "./components/WhyTaxBack";
import Interview from "./components/Interview";
import Flows from "./components/Flows";
import HowTo from "./components/HowTo";
import WithPrior from "./components/WithPrior";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import "./style/css/style.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Reset />
        <Skip />
        <NavBar />
        <Intro />
        <WhyTaxBack />
        <Interview />
        {/* <Flows /> */}
        <HowTo />
        <WithPrior />
        <Faq />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
