import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import ThumbKim from "../assets/thumbnail-kim.png"
import ThumbKwak from "../assets/thumbnail-kwak.png"
import ThumbAhn from "../assets/thumbnail-ahn.png"

export default function Interview(){
  return(
    <div className="interview" id="useTb">
      <div className="interview-inner">
        <h2 className="interview-title"><span>환급 받은 분들</span>의 이야기를 들어보세요.</h2>  
        <Swiper
          navigation={true}
          modules={[Navigation]}
          loop={true}
          className="interview-swiper"
        >
          <SwiperSlide
            className="interview-big"
          >
            <div className="interview-big-left">
              <video 
                className='interview-big-left-video'
                poster={ThumbKim}
                controls
              >
                <source src={require('../assets/videos/interview-kim.mp4')} />
              </video>
              <p className='interview-big-left-who'><span>양도세 환급</span> | 김*하 자영업</p>
            </div>
            <div className="interview-big-right">
              <div className="interview-big-right-title">
                <p>
                  <span>&ldquo;</span>
                  힘든 시기에 잃어버린 권리를 <br />찾아줘서 너무 감사해요
                  <span>&rdquo;</span>
                </p>
              </div>
              <div className="interview-big-right-context">
                <p>
                양도세 전문 세무사와 직접 상담하고 진행할 수 있어서 신뢰할 수 있는 곳이라 생각했습니다. 부산의 제 사업장까지 오셔서 양도세 뿐만 아니라 다른 재산에 관련된 상담도 같이 할 수 있어서 좋았습니다. 
                <br />
                <br />
                자영업을 하니까 코로나 이후에 힘들었는데 택스백이 힘든 시기에 잃어버린 권리를 찾아준 것 같아 너무 고맙습니다. 
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="interview-big"
          >
            <div className="interview-big-left">
              <video 
                className='interview-big-left-video'
                poster={ThumbKwak}
                controls
              >
                <source src={require('../assets/videos/interview-kwak.mp4')} />
              </video>
              <p className='interview-big-left-who'><span>양도세 환급</span> | 곽*자 가정주부</p>
            </div>
            <div className="interview-big-right">
              <div className="interview-big-right-title">
                <p>
                  <span>&ldquo;</span>
                  양도세 전문 환급 서비스는 <br />처음 봤어요
                  <span>&rdquo;</span>
                </p>
              </div>
              <div className="interview-big-right-context">
                <p>
                양도세 낸 금액이 많이 커서 신뢰할 수 있는 세무법인을 찾던 중, 택스백이 경험도 많고 전문성이 있다고 해서 선택하게 되었습니다.  
                <br />
                <br />
                유일한 양도소득세 전문 세무법인이라 믿고 맡길 수 있었어요. 따로 양도세 관련 부서가 형성되어 있더라고요. 전문성 있는 세무사와 직접 상담, 소통한 후에 최종적으로 60,172,230원 환급받을 수 있었습니다. 
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="interview-big"
          >
            <div className="interview-big-left">
              <video 
                className='interview-big-left-video'
                poster={ThumbAhn}
                controls
              >
                <source src={require('../assets/videos/interview-ahn.mp4')} />
              </video>
              <p className='interview-big-left-who'><span>양도세 환급</span> | 안*현 가정주부</p>
            </div>
            <div className="interview-big-right">
              <div className="interview-big-right-title">
                <p>
                  <span>&ldquo;</span>
                  고민의 시간은 <br />돈을 돌려받을 시간을 놓치는 거예요
                  <span>&rdquo;</span>
                </p>
              </div>
              <div className="interview-big-right-context">
                <p>
                양도소득세도 처음 내보고, 경정청구도 잘 몰랐는데 택스백 통해서 간편하게 인증하고 환급 받았습니다. 
                <br />
                <br />
                불안한 마음에 직접 세무사 사무실에도 방문했었는데 장서환 대표 세무사님께서 꼼꼼하게 설명해주셔서 믿음을 가지고 환급 절차를 밟을 수 있었어요.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}