import { useEffect, useState } from "react";
import priorLogoW from "../assets/prior-w-logo.png";
import priorPeopleMo from "../assets/prior-people-mo.png";
import { throttle } from "lodash";

export default function WithPrior() {
  const [mobileTurn, setMobileTurn] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      throttle(() => {
        window.innerWidth < 768 ? setMobileTurn(true) : setMobileTurn(false);
      }, 500)
    );

    window.innerWidth < 768 ? setMobileTurn(true) : setMobileTurn(false);
  }, []);

  return (
    <section className="with" id="withPr">
      <h2 className="hidden">믿을 수 있는 세무법인 프라이어</h2>
      {!mobileTurn && (
        <div className="prior">
          <div className="prior-dim"></div>
          <div className="prior-text">
            <p className="prior-text-title">믿을 수 있는 세무법인 프라이어</p>
            <p className="prior-text-cont">
              국세청 출신 및 분야별 전문 세무사 20명,
              <br />
              숙련된 전문 세무 직원 80명이
              <br />
              최대한의 환급 금액을 찾아드리고 사후 관리까지 책임집니다.
            </p>
            <img src={priorLogoW} alt="" />
          </div>
        </div>
      )}
      {mobileTurn && (
        <div className="prior-mo">
          <img src={priorPeopleMo} className="prior-mo-people" alt="" />
          <div className="prior-mo-text">
            <p className="prior-mo-text-title">
              믿을 수 있는
              <br />
              세무법인 리원
              <br />
              세무법인 프라이어
            </p>
            <p className="prior-mo-text-cont">
              국세청 출신 및 분야별 전문 세무사 20명,
              <br />
              숙련된 전문 세무 직원 80명이
              <br />
              최대한의 환급 금액을 찾아드리고
              <br />
              사후 관리까지 책임집니다.
            </p>
            <img src={priorLogoW} alt="" />
          </div>
        </div>
      )}
    </section>
  );
}
