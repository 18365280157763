import { useEffect } from "react";

export default function WhyTaxBack() {
  let timer;
  function debounce(method, delay) {
    clearTimeout(timer);
    timer = setTimeout(function () {
      method();
    }, delay);
  }

  const handleScroll = () => {
    if (window.scrollY > document.querySelector(".why").offsetTop - 450) {
      const speed = 80;
      const counters = document.querySelectorAll(".figure-list-count span");
      counters.forEach((counter) => {
        const animate = () => {
          const value = +counter.getAttribute("max");
          const data = +counter.innerHTML;
          const time = value / speed;
          if (data < value) {
            counter.innerHTML = Math.ceil(data + time);
            setTimeout(animate, 10);
          } else {
            counter.innerHTML = value;
          }
        };
        animate();
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      debounce(handleScroll, 100);
    });
  }, []);

  return (
    <section className="why" id="whyTb">
      <h2 className="hidden">택스백을 선택하는 이유</h2>
      <div className="figure">
        <p className="figure-title">
          복잡한 세무 신고 절차는 이제 그만!
          <br />
          <span>간단한 신청</span>만으로 <span>세금 환급</span> 받으세요
        </p>
      </div>
    </section>
  );
}
