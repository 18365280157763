import { useState, useEffect, useRef } from "react";
import FaqIcon from "../assets/faq-icon.png";
import { ReactComponent as FaqMark } from "../assets/faq-mark.svg";
import { ReactComponent as FaqPlus } from "../assets/faq-plus.svg";
import { ReactComponent as FaqInfo } from "../assets/faq-info.svg";

export default function Faq() {
  const faqList = useRef();
  const [faqOpen, setFaqOpen] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= document.querySelector(".faq").offsetTop - 500) {
        faqList.current.className = "faq-list faq-list-on";
      } else {
        faqList.current.className = "faq-list";
      }
    });
  }, []);

  return (
    <div className="faq" id="faqTb">
      <section className="faq-inner">
        <div className="faq-top">
          <img src={FaqIcon} alt="" />
          <h2 className="faq-title">궁금한 점이 있으신가요?</h2>
        </div>
        <ul className="faq-list" ref={faqList}>
          <li>
            <div
              className="faq-list-question"
              onClick={() => setFaqOpen({ ...faqOpen, first: !faqOpen.first })}
            >
              <FaqMark className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-question-text">
                기장을 맡기고 있는 세무사 사무실이 있는데 가능한가요?
              </p>
              <FaqPlus
                className={`faq-list-icon ${
                  faqOpen.first && `faq-list-icon-on`
                }`}
              />
            </div>
            <div
              className={`faq-list-answer ${
                faqOpen.first && `faq-list-answer-on`
              }`}
            >
              <FaqInfo className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-answer-text">
                경정청구 업무는 기존의 세무사와 상관없이 진행할 수 있으며,
                기존에 연결된 세무대리인이 변경되지 않습니다. 다양한 케이스의
                경정청구 진행 경험을 보유한 전문 세무사를 통해 완벽한 세액공제를
                받아 보시는 것이 납세자 입장에서 유리합니다.
              </p>
            </div>
          </li>
          <li>
            <div
              className="faq-list-question"
              onClick={() =>
                setFaqOpen({ ...faqOpen, second: !faqOpen.second })
              }
            >
              <FaqMark className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-question-text">
                이미 다른 곳을 통해 세금 환급을 받은 이력이 있습니다. 해당이
                안되는 건가요?
              </p>
              <FaqPlus
                className={`faq-list-icon ${
                  faqOpen.second && `faq-list-icon-on`
                }`}
              />
            </div>
            <div
              className={`faq-list-answer ${
                faqOpen.second && `faq-list-answer-on`
              }`}
            >
              <FaqInfo className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-answer-text">
                경정청구의 횟수제한은 없습니다. 누락된 추가 공제, 감면 사항이
                있다면 추가 환급이 가능합니다. PRIOR 경정청구 시스템은 고객의
                상황에 따라 최대한으로 공제가 반영될 수 있도록 개발되어
                있습니다. 따라서 기존에 세금환급 경험이 있더라도 추가로 환급받을
                가능성이 높습니다.
              </p>
            </div>
          </li>
          <li>
            <div
              className="faq-list-question"
              onClick={() => setFaqOpen({ ...faqOpen, third: !faqOpen.third })}
            >
              <FaqMark className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-question-text">
                경정청구하면 세무조사가 나오는 거 아닌가요?
              </p>
              <FaqPlus
                className={`faq-list-icon ${
                  faqOpen.third && `faq-list-icon-on`
                }`}
              />
            </div>
            <div
              className={`faq-list-answer ${
                faqOpen.third && `faq-list-answer-on`
              }`}
            >
              <FaqInfo className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-answer-text">
                경정청구와 세무조사는 무관합니다. 세무조사 대상자는 국세기본법에
                의해 정기적으로 선정하거나, 신고의 성실도에 따라 비정기적으로
                선정합니다. 경정청구는 누락된 공제, 세제 혜택을 적용하여
                납세자의 정당한 권리를 찾아 드리는 것으로, 경정청구로 인해
                세무조사의 대상이 되지 않습니다.
              </p>
            </div>
          </li>
          <li>
            <div
              className="faq-list-question"
              onClick={() =>
                setFaqOpen({ ...faqOpen, fourth: !faqOpen.fourth })
              }
            >
              <FaqMark className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-question-text">
                세금 환급까지 시간이 얼마나 걸리나요?
              </p>
              <FaqPlus
                className={`faq-list-icon ${
                  faqOpen.fourth && `faq-list-icon-on`
                }`}
              />
            </div>
            <div
              className={`faq-list-answer ${
                faqOpen.fourth && `faq-list-answer-on`
              }`}
            >
              <FaqInfo className="faq-list-icon faq-list-icon-mark" />
              <p className="faq-list-answer-text">
                1시간의 환급액 조회 이후, 환급 신청까지는 3일 정도가 소요되며,
                국세청 검토 및 최종 환급까지는 2개월 정도가 소요됩니다.
              </p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}
