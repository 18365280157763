export default function Skip(){
  return(
    <ul className="skip">
      <li>
        <a href="#navBar" title="처음으로" className="skip-btn">처음으로</a>
      </li>
      <li>
        <a href="#whyTb" title="Why Taxback" className="skip-btn">Why Taxback &#40;왜 택스백을 이용해야하나요?&#41;</a>
      </li>
      <li>
        <a href="#useTb" title="Interview" className="skip-btn">Interview &#40;환급 받은 분들의 이야기를 들어보세요.&#41;</a>
      </li>
      <li>
        <a href="#howTb" title="How To" className="skip-btn">How To &#40;택스백의 환급방식&#41;</a>
      </li>
      <li>
        <a href="#withPr" title="with Prior" className="skip-btn">with Prior &#40;믿을 수 있는 세무법인 프라이어&#41;</a>
      </li>
      <li>
        <a href="#faqTb" title="Faq" className="skip-btn">Faq &#40;자주 묻는 질문&#41;</a>
      </li>
      <li>
        <a href="#footerTb" title="기업 정보" className="skip-btn">Footer &#40;기업 정보&#41;</a>
      </li>
    </ul>
  )
}